import React from "react"
import Footer from "../components/footer"
import SEO from "../components/seo"

import ogImageImpressum from "../images/og-image/og-image-impressum.png"
export default function Impressum() {
  return (
    <>
      <SEO
        title="Impressum - Cuuno"
        description="Hier finden Sie alle Information bezüglich unserer Gbr und der Adresse, Anschrift und Bankverbinung. Angaben sind gemäß § 5 TMG."
        image={ogImageImpressum}
        defaultTitle="Impressum - Cuuno"
        titleTemplate="Impressum"
      />
      <div className="cb-container">
        
            <div class="cb-header -info">
              <h1>
                Impressum <br />
              </h1>
            </div>
            {/* Section 1*/}
            <div class="cb-subheader">
              <h1> Angaben gemäß § 5 TMG</h1>
            </div>
            <div class="cb-text">
              <p>
                Cuuno Digital Agency
                <br />
                Johannes Schwegler und Oliver Hagel GbR
                <br />
                Hopfenweg 12
                <br />
                88326 Aulendorf
              </p>
            </div>

            {/* Section 2*/}
            <div class="cb-subheader">
              <h1> Vertreten durch</h1>
            </div>
            <div class="cb-text">
              <p>
                Johannes Schwegler (Geschäftsführer)
                <br />
                Oliver Hagel (Geschäftsführer)
        
              </p>
            </div>

            {/* Section 3*/}
            <div class="cb-subheader">
              <h1> Kontakt</h1>
            </div>
            <div class="cb-text">
              <p>
                Tel. 07525 7724
                <br />
                partnerships@cuuno.com
              
              </p>
            </div>
        
      </div>
      <Footer />
    </>
  )
}
